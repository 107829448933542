import { environment } from "src/environments/environment";

export class Programme {
    id: string;
    title: string;
    description : string
    debut: string;
    fin: string;
    jour : string;
    picture: string;
    created_at: string;
    



    constructor(data) {
        this.id = data._id;
        this.title = data.title;
        this.description = data.description;
        this.debut = data.debut;
        this.fin = data.fin
        this.jour = data.jour
        this.picture =  environment.backendEndpoint + data.file
        this.created_at = data.created_at
    }
}
