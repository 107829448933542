import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './layout/navbar/navbar.component';
import { FooterComponent } from './layout/footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomePageComponent } from './home-page/home-page.component';
import { SharedModule } from './shared/shared.module';
import { ContactComponent } from './contact/contact.component';
import { ActualitesComponent } from './actualites/actualites.component';
import { AudioComponent } from './audio/audio.component';
import { EquipeComponent } from './equipe/equipe.component';
import { ProgrammeComponent } from './programme/programme.component';
import { EmissionComponent } from './emission/emission.component';
import { PubliciteComponent } from './publicite/publicite.component';
import { AinNefzawaComponent } from './ain-nefzawa/ain-nefzawa.component';
import { NefzawaDashboardComponent } from './nefzawa-dashboard/nefzawa-dashboard.component';
import { LoginComponent } from './login/login.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptorService } from './core/interceptors/token-interceptor.service';
import { BraodcastComponent } from './braodcast/braodcast.component';
import { ActualiteDetailsComponent } from './actualite-details/actualite-details.component';
import { VideoNFComponent } from './video-nf/video-nf.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { VideoNTVComponent } from './video-ntv/video-ntv.component';
import { NousComponent } from './nous/nous.component';
import { Mouwafa9Component } from './mouwafa9/mouwafa9.component';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { Mitha9Component } from './mitha9/mitha9.component';
import { LiveComponent } from './live/live.component';
import { CooworkingComponent } from './cooworking/cooworking.component';
import { OndesComponent } from './ondes/ondes.component';
import { HistoireComponent } from './histoire/histoire.component';
import { MazedComponent } from './mazed/mazed.component';
import { GieComponent } from './gie/gie.component';
import { MasComponent } from './mas/mas.component';
import { FacebookModule } from 'ngx-facebook';
import { TourismeupComponent } from './tourismeup/tourismeup.component';
import { MatSnackBarModule } from '@angular/material';
import { MeteoComponent } from './meteo/meteo.component';

import {ToggleButtonModule} from 'primeng/togglebutton';
import { InterceptorService } from './core/services/interceptor.service';
import { ProductionComponent } from './production/production.component';
import { NewletterComponent } from './newletter/newletter.component';
import { BodcastHomeComponent } from './bodcast-home/bodcast-home.component';
import { Bodcast1Component } from './bodcast1/bodcast1.component';
import { Broadcast2Component } from './broadcast2/broadcast2.component';
import { AlonefzawaComponent } from './alonefzawa/alonefzawa.component';


@NgModule({
  declarations: [	
    AppComponent,
    NavbarComponent,
    FooterComponent,
    HomePageComponent,
    ContactComponent,
    ActualitesComponent,
    AudioComponent,
    EquipeComponent,
    ProgrammeComponent,
    EmissionComponent,
    PubliciteComponent,
    AinNefzawaComponent,
    LoginComponent,
    BraodcastComponent,
    ActualiteDetailsComponent,
    VideoNFComponent,
    VideoNTVComponent,
    NousComponent,
    Mouwafa9Component,
    Mitha9Component,
    LiveComponent,
    CooworkingComponent,
    OndesComponent,
    HistoireComponent,
    MazedComponent,
    GieComponent,
    MasComponent,
    TourismeupComponent,
    MeteoComponent,
    ProductionComponent,
    NewletterComponent,
    BodcastHomeComponent,
      Bodcast1Component,
      Broadcast2Component,
      AlonefzawaComponent,
      
     ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    YouTubePlayerModule,
    FacebookModule,
    MatSnackBarModule,
    ToggleButtonModule,
    FacebookModule.forRoot()


  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    {provide : LocationStrategy , useClass : HashLocationStrategy},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
  ],
  

 
  bootstrap: [AppComponent]
})
export class AppModule { }
