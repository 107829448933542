import { Component, Input, OnInit } from '@angular/core';
import { Broadcast } from 'src/app/core/models/broadcast';

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.css']
})
export class PlayerComponent implements OnInit {

  @Input() broadcast;

  constructor() { }

  ngOnInit() {
  }

}
