import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Post } from '../core/models/post';
import { BackEndApiService } from '../core/services/back-end-api.service';

@Component({
  selector: 'app-braodcast',
  templateUrl: './braodcast.component.html',
  styleUrls: ['./braodcast.component.css']
})
export class BraodcastComponent implements OnInit {

  bc : any ; 
  public broadcasts: any[] = []
  _postsAlamia: Post[] = []
  _posts: Post[] = []
  videos : any = [
    {"title":"بودكاست الدستور على نفزاوة - 1","file":"https://www.youtube.com/embed/Nmv_VmZFiyU"},
    {"title":"بودكاست الدستور على نفزاوة - 2","file":"https://www.youtube.com/embed/rbFZuONmdF4"},
    {"title":"بودكاست الدستور على نفزاوة - 3","file":"https://www.youtube.com/embed/ditFLzVK__w"},
    {"title":"بودكاست الدستور على نفزاوة - 4","file":"https://www.youtube.com/embed/20dk6wtfgeo"},
  
    {"title":"بودكاست الدستور على نفزاوة - 5","file":"https://www.youtube.com/embed/qFOpJk-7cS0"},
    {"title":"بودكاست الدستور على نفزاوة - 6","file":"https://www.youtube.com/embed/eu8rrKTkWlk"},
    {"title":"بودكاست الدستور على نفزاوة - 7","file":"https://www.youtube.com/embed/4B7QPcJAvHQ"},
    {"title":"بودكاست الدستور على نفزاوة - 8","file":"https://www.youtube.com/embed/fQ1_IVuwFc0"},
  {"title":"بودكاست الدستور على نفزاوة - 9","file":"https://www.youtube.com/embed/rh-ZGLUBSOs"},
  {"title":"بودكاست الدستور على نفزاوة - 10","file":"https://www.youtube.com/embed/kCyw7UxRu1U"},
  ]
  constructor(
    private backEndApiService: BackEndApiService
  ) { }

  ngOnInit() {
    this.getAllBroadcasts()    
    this.get4PostsAlamia()
    this.get4Posts()

    
    console.log(this.broadcasts)

    localStorage.setItem('test', JSON.stringify(this.broadcasts));
    var retrievedObject = localStorage.getItem('test');

    console.log('test ', JSON.parse(retrievedObject));
  }

  getAllBroadcasts () {
    let i = 0;
    this.backEndApiService.getAllBroadcasts().subscribe((response: any) => {
      response.map((broadcast: any) => {
       // console.log(broadcast);
        
        this.broadcasts.push({
          url : environment.backendEndpoint+broadcast.file,
          title: broadcast.title,
          // cover: environment.backendEndpoint+broadcast.file
      })
 
      
      })
    })

    localStorage.setItem('test', JSON.stringify(
      this.broadcasts
));

  }

  get4PostsAlamia () {
    this.backEndApiService.get4PostsAlamia().subscribe((response: any) => {
      response.map((post: any) => {
        post = new Post(post);
        this._postsAlamia.push(post)
      })
    })
  }

  get4Posts () {
    this.backEndApiService.get4Posts().subscribe((response: any) => {
      response.map((post: any) => {
        post = new Post(post);
        this._posts.push(post)
      })
    })
  }
}
