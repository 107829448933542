import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BackEndApiService {

  baseUrl = environment.backendEndpoint;

  constructor(
    private http: HttpClient
  ) {
  }

  login(body) {
    return this.http.post(this.baseUrl + 'users/login', body);
  }

  logout () {
    localStorage.clear();
  }

  dataHomePage () {
    return this.http.get(this.baseUrl + 'posts/get/last');
  }

  getAllTitlePosts () {
    return this.http.get(this.baseUrl + 'posts/get/title');
  }

  // Post
  createPost(formData: FormData) {
    return this.http.post(this.baseUrl + 'posts/create', formData);
  }


  updatePost(formData: FormData) {
    return this.http.post(this.baseUrl + 'posts/update', formData);
  }


  createAudio(formData: FormData) {
    return this.http.post(this.baseUrl + 'audio/create', formData);
  }



  createLive(formData: FormData) {

    return this.http.post(this.baseUrl + 'live/create', formData);
  }


  getLive() {
    return this.http.get(this.baseUrl + 'live/all' );
  }


  

  changePage(id:any) {

    return this.http.get(this.baseUrl + 'live/changeLive/' +id);
  
  }


  getAllPostsByCategory(category) {
    return this.http.get(this.baseUrl + 'posts/all/'+ category);
  }

  getPostById (postId) {
    return this.http.get<any>(this.baseUrl + 'posts/' + postId)
  }

  deletePost(postId) {
    return this.http.get(this.baseUrl + 'posts/delete/' + postId)
  }

  get4Posts () {
    return this.http.get(this.baseUrl + 'posts/get/last/4');
  }

  get4PostsAlamia () {
    return this.http.get(this.baseUrl + 'posts/get/alamia/4')
  }

  // Equipe
  createEquipe(formData: FormData) {
    return this.http.post(this.baseUrl + 'equipes/create', formData);
  }

  getAllEquipes () {
    return this.http.get(this.baseUrl + 'equipes/all')
  }

  deleteEquipe(equipeId) {
    return this.http.get(this.baseUrl + 'equipes/delete/' + equipeId)
  }

  // Broadcast
  createBroadcast(formData: FormData) {
    return this.http.post(this.baseUrl + 'broadcasts/create', formData);
  }

  getAllBroadcasts () {
    return this.http.get(this.baseUrl + 'broadcasts/all')
  }

  deleteBroadcast(equipeId) {
    return this.http.get(this.baseUrl + 'broadcasts/delete/' + equipeId)
  }

  // Programme
  createProgramme (body) {
    return this.http.post(this.baseUrl + 'programmes/create', body)
  }

  getAllProgrammes () {
    return this.http.get(this.baseUrl + 'programmes/all')
  }

  deleteProgramme (programmeId) {
    return this.http.get(this.baseUrl + 'programmes/delete/' + programmeId)
  }
   // ProgrammeGlobal
   createProgrammeGlobal (body) {
    return this.http.post(this.baseUrl + 'programmesGlobal/create', body)
  }

  getAllProgrammesGlobal () {
    return this.http.get(this.baseUrl + 'programmesGlobal/all')
  }

  deleteProgrammeGlobal (programmeId) {
    return this.http.get(this.baseUrl + 'programmesGlobal/delete/' + programmeId)
  }



   // ProgrammeGlobal
   createProgrammeApplication (body) {
    return this.http.post(this.baseUrl + 'programmeApplication/create', body)
  }

  getAllProgrammeApplication() {
    return this.http.get(this.baseUrl + 'programmeApplication/all')
  }

  deleteProgrammeApplication (programmeId) {
    return this.http.get(this.baseUrl + 'programmeApplication/delete/' + programmeId)
  }




  // ProgrammeMobile
  createProgrammeMobile (body) {
    return this.http.post(this.baseUrl + 'programmesMobile/create', body)
  }

  getAllProgrammesMobile () {
    return this.http.get(this.baseUrl + 'programmesMobile/all')
  }

  deleteProgrammeMobile (programmeId) {
    return this.http.get(this.baseUrl + 'programmesMobile/delete/' + programmeId)
  }

   // Publicite
   createPub (body) {
    return this.http.post(this.baseUrl + 'pubs/create', body)
  }

  getAllPubs () {
    return this.http.get(this.baseUrl + 'pubs/all')
  }

  deletePub (programmeId) {
    return this.http.get(this.baseUrl + 'pubs/delete/' + programmeId)
  }

  // Visit
  createVisit (body) {
    return this.http.post(this.baseUrl + 'visits/create', body)
  }

  getAllVisits () {
    return this.http.get(this.baseUrl + 'visits/all')
  }

  deleteVisit (programmeId) {
    return this.http.get(this.baseUrl + 'visits/delete/' + programmeId)
  }
  // Produce
  createProduce (body) {
    return this.http.post(this.baseUrl + 'produces/create', body)
  }

  getAllProduces () {
    return this.http.get(this.baseUrl + 'produces/all')
  }

  deleteProduce (programmeId) {
    return this.http.get(this.baseUrl + 'produces/delete/' + programmeId)
  }
  // Tv
  createTV (body) {
    return this.http.post(this.baseUrl + 'tvs/create', body)
  }

  getAllTvss () {
    return this.http.get(this.baseUrl + 'tvs/all')
  }

  deleteTV (programmeId) {
    return this.http.get(this.baseUrl + 'tvs/delete/' + programmeId)
  }
  //baldeya
  createBALDEYA (body) {
    return this.http.post(this.baseUrl + 'baladeyas/create', body)
  }

  getAllBaladeyas () {
    return this.http.get(this.baseUrl + 'baladeyas/all')
  }

  deleteBALADEYA (programmeId) {
    return this.http.get(this.baseUrl + 'baladeyas/delete/' + programmeId)
  }
  //projetBaladeya
  createPBALADEYA (body) {
    return this.http.post(this.baseUrl + 'projetBaladeyas/create', body)
  }

  getAllPBALADEYAS() {
    return this.http.get(this.baseUrl + 'projetBaladeyas/all')
  }

  deletePBALADEYA (programmeId) {
    return this.http.get(this.baseUrl + 'projetBaladeyas/delete/' + programmeId)
  }
  //GIE
  createGIE (body) {
    return this.http.post(this.baseUrl + 'gie/create', body)
  }

  getAllGIE() {
    return this.http.get(this.baseUrl + 'gie/all')
  }

  deleteGIE (programmeId) {
    return this.http.get(this.baseUrl + 'gie/delete/' + programmeId)
  }
  //projetMASS
  createMASS (body) {
    return this.http.post(this.baseUrl + 'mass/create', body)
  }

  getAllMASS() {
    return this.http.get(this.baseUrl + 'mass/all')
  }

  deleteMASS (programmeId) {
    return this.http.get(this.baseUrl + 'mass/delete/' + programmeId)
  }
  //projetONDES
  createONDES (body) {
    return this.http.post(this.baseUrl + 'onde/create', body)
  }

  getAllONDES() {
    return this.http.get(this.baseUrl + 'onde/all')
  }

  deleteONDES (programmeId) {
    return this.http.get(this.baseUrl + 'onde/delete/' + programmeId)
  }
  //EVENT
  createEVENT (body) {
    return this.http.post(this.baseUrl + 'event/create', body)
  }

  getAllEVENT() {
    return this.http.get(this.baseUrl + 'event/all')
  }

  deleteEVENT (programmeId) {
    return this.http.get(this.baseUrl + 'event/delete/' + programmeId)
  }
  //newsletter
  createNewsletter (body) {
    return this.http.post(this.baseUrl + 'newsLetter/create', body)
  }

  getAllNewsletter() {
    return this.http.get(this.baseUrl + 'newsLetter/all')
  }

  deleteNewsletter (programmeId) {
    return this.http.get(this.baseUrl + 'newsLetter/delete/' + programmeId)
  }
  //USERS
  registerUser (body) {
    return this.http.post(this.baseUrl + 'users/register', body)
  }

  getAllUser() {
    return this.http.get(this.baseUrl + 'users/all')
  }

  deleteUser (programmeId) {
    return this.http.get(this.baseUrl + 'users/delete/' + programmeId)
  }

  showPost(id) {
    return this.http.get(this.baseUrl+'posts/showPost/'+id)
  }
  hidePost(id) {
    return this.http.get(this.baseUrl+'posts/hidePost/'+id)
  }
    
  changeCodePub (id , code) {
    return this.http.get(this.baseUrl + 'live/updateCodePub/' + id+'/'+code)
  }


}
