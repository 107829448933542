import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-broadcast2',
  templateUrl: './broadcast2.component.html',
  styleUrls: ['./broadcast2.component.css']
})
export class Broadcast2Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
