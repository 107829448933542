export class NewsLetter {
  id: string;
  email: string;
  password: string;
  created_at: string;
  



  constructor(data) {
      this.id = data._id;
      this.email = data.email;
      this.password= data.password;
      
      this.created_at = data.created_at;
  }
}