import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BackEndApiService } from 'src/app/core/services/back-end-api.service';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  public submitted = false;
  public emailErrorMessage: string;

  constructor(
    private formBuilder: FormBuilder,
    private backEndApiService: BackEndApiService,
    private router: Router,
   
  ) { }

  ngOnInit(): void {

    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

  }

  get f() { return this.loginForm.controls; }

  login () {
    this.submitted = true;
    this.emailErrorMessage = '';
    if (this.loginForm.invalid) {
      return;
      
    }
    let body = {
      'email' : this.loginForm.value.username,
      'password'  : this.loginForm.value.password
    };
    this.backEndApiService.login(body).subscribe((response: any) => {
        localStorage.setItem('isLoggedin', 'true');
        localStorage.setItem('token', response.token);
        localStorage.setItem('email', response.email);
        localStorage.setItem('role', response.role);
        this.router.navigate(['nefzawa/wp-admin'])
    }, (error: any) => {      
      this.submitted = true; 
      this.emailErrorMessage = "S'il vous plaît vérifier votre Email ou Mot de passe" ;
      
     
    });
  }
}
