import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { BackEndApiService } from '../core/services/back-end-api.service';

@Component({
  selector: 'app-live',
  templateUrl: './live.component.html',
  styleUrls: ['./live.component.css']
})
export class LiveComponent implements OnInit {

  constructor(private http : HttpClient , private backEndApiService : BackEndApiService) { }

  current : any ; 
  live : any ; 
  ngOnInit() {

    this.backEndApiService.getLive().subscribe(res => this.current = res[0].title , ex => console.log(ex)) ;

    this.http.get<any>("http://localhost:1212/live/all").subscribe(res => this.live = res[0].description , ex => console.log(ex))
  }

}
