import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-video-ntv',
  templateUrl: './video-ntv.component.html',
  styleUrls: ['./video-ntv.component.css']
})
export class VideoNTVComponent implements OnInit {

  constructor() { }
  private apiLoaded = false;

  ngOnInit() {


    if(!this.apiLoaded) {
            const tag = document.createElement('script');
            tag.src = 'http://www.youtube.com/watch?v=O47uUnJjbJc';
            document.body.appendChild(tag);
            this.apiLoaded = true;
          }
  }

  

}
