import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tourismeup',
  templateUrl: './tourismeup.component.html',
  styleUrls: ['./tourismeup.component.css']
})
export class TourismeupComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
