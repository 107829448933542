import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  public titlePage = new Subject()
  public postId = new Subject()

  constructor() { }
}
