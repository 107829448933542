import { Component, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Post } from 'src/app/core/models/post';
import { StoreService } from 'src/app/core/services/store.service';

@Component({
  selector: 'app-news-item-no-screen',
  templateUrl: './news-item-no-screen.component.html',
  styleUrls: ['./news-item-no-screen.component.scss']
})
export class NewsItemNoScreenComponent implements OnInit {

  @Input() newsDetails: any;
  @Input() post: Post

  constructor(
    private storeService: StoreService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  navigateToDetails (postId) {
    this.storeService.postId.next(postId);
    this.router.navigate(['/actualite-details', postId])
    window.scroll(0, 0)
  }
}
