import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Programme } from 'src/app/core/models/programme';
import { BackEndApiService } from 'src/app/core/services/back-end-api.service';
import { StoreService } from 'src/app/core/services/store.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  baseUrl = environment.backendEndpoint;
  schedular : any ; 
  schedularMobile : any ; 
  closeResult = '';
  newsList: boolean = false;
  sportList: boolean = false;
  mounawaatList: boolean = false;
  itheaaList: boolean = false;
  teamList: boolean = false;
  directList: boolean = false;
  directLinkList: boolean = false;
  anemiList: boolean = false;
  public programmes: Programme[] = []

  constructor(
    public router: Router,
    private storeService: StoreService,
    private backEndApiService: BackEndApiService,
    private http :HttpClient
  ) {}

  ngOnInit(): void {

    this.http.get<any>(this.baseUrl+'programmes/one').subscribe(res=>this.schedular = res.file , ex => console.log(ex)) ;
    this.http.get<any>(this.baseUrl+'programmesMobile/one').subscribe(res=>this.schedularMobile = res.file , ex => console.log(ex)) ;

    this.getAllTitlePosts();
    this.getAllProgrammes()
  }

  closeSidenav(page = '') {
    this.storeService.titlePage.next(page);
    this.itheaaList = false
    this.mounawaatList = false
    this.sportList = false;
    this.newsList = false;
    this.teamList = false;
    this.directList = false
    this.directLinkList = false;
    this.anemiList = false;
  }
  
  slides = [
    {img: "https://res.cloudinary.com/practicaldev/image/fetch/s--JhIGUN8T--/c_imagga_scale,f_auto,fl_progressive,h_420,q_auto,w_1000/https://dev-to-uploads.s3.amazonaws.com/uploads/articles/4ssdpyqehk4rkac9a3kb.jpg"},
    {img: "https://res.cloudinary.com/practicaldev/image/fetch/s--JhIGUN8T--/c_imagga_scale,f_auto,fl_progressive,h_420,q_auto,w_1000/https://dev-to-uploads.s3.amazonaws.com/uploads/articles/4ssdpyqehk4rkac9a3kb.jpg"},
    {img: "https://res.cloudinary.com/practicaldev/image/fetch/s--JhIGUN8T--/c_imagga_scale,f_auto,fl_progressive,h_420,q_auto,w_1000/https://dev-to-uploads.s3.amazonaws.com/uploads/articles/4ssdpyqehk4rkac9a3kb.jpg"},
    {img: "https://res.cloudinary.com/practicaldev/image/fetch/s--JhIGUN8T--/c_imagga_scale,f_auto,fl_progressive,h_420,q_auto,w_1000/https://dev-to-uploads.s3.amazonaws.com/uploads/articles/4ssdpyqehk4rkac9a3kb.jpg"}
  ];
  slideConfig = {"slidesToShow": 4, "slidesToScroll": 4};
  
  addSlide() {
    this.slides.push({img: "http://placehold.it/350x150/777777"})
  }
  
  removeSlide() {
    this.slides.length = this.slides.length - 1;
  }
  
  slickInit(e) {
    console.log('slick initialized');
  }
  
  breakpoint(e) {
    console.log('breakpoint');
  }
  
  afterChange(e) {
    console.log('afterChange');
  }
  
  beforeChange(e) {
    console.log('beforeChange');
  }

  getAllProgrammes () {
    this.backEndApiService.getAllProgrammes().subscribe((response: any) => {
      response.map((programme: any) => {
        programme = new Programme(programme);
        this.programmes.push(programme)
      })
    })    
  }

  public response: any[]

  getAllTitlePosts() {
    this.backEndApiService.getAllTitlePosts().subscribe((response: any) => {
      this.response = response
    })
  }








}
