import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Post } from '../core/models/post';
import { BackEndApiService } from '../core/services/back-end-api.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {

  postType = ['gbeli', 'watania']
  gbeliPosts: Post[] = []
  wataniaPosts: Post[] = []
  schedular : any ;
  pub : any ;
  baseUrl = environment.backendEndpoint;

  constructor(
    private backEndApiService: BackEndApiService , private http : HttpClient
  ) { }

  ngOnInit() {
    this.getDataHomePage()
    console.log(this.wataniaPosts);
    this.http.get<any>(this.baseUrl+"pubs/all").subscribe(res => this.pub = res[0].file , ex => console.log(ex) ) ;
    
    
  }

  getDataHomePage () {
    this.backEndApiService.dataHomePage().subscribe((response: any) => {
      response.gbeli.map((post: any) => {

        post = new Post(post)
 
        if(post.status === true){
        
          this.gbeliPosts.push(post)
        }

        
      })
      response.watania.map((post: any) => {
    
        post = new Post(post)



         if(post.status === true){
        
           this.wataniaPosts.push(post)
         }
      })
    })
  }
}
