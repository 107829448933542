import { Component, Input, OnInit } from '@angular/core';
import { Post } from 'src/app/core/models/post';

@Component({
  selector: 'app-news-item-screen',
  templateUrl: './news-item-screen.component.html',
  styleUrls: ['./news-item-screen.component.scss']
})
export class NewsItemScreenComponent implements OnInit {

  @Input() programme?: boolean = true;
  @Input() post?: Post
  
  constructor() { }

  ngOnInit() {
  }

}
