import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gie',
  templateUrl: './gie.component.html',
  styleUrls: ['./gie.component.css']
})
export class GieComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  counter(i: number) {
    return new Array(i);
}

}
