import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mitha9',
  templateUrl: './mitha9.component.html',
  styleUrls: ['./mitha9.component.css']
})
export class Mitha9Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  counter(i: number) {
    return new Array(i);
}
}
