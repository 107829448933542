import { Component, OnInit } from '@angular/core';
import { Post } from '../core/models/post';
import { BackEndApiService } from '../core/services/back-end-api.service';

@Component({
  selector: 'app-publicite',
  templateUrl: './publicite.component.html',
  styleUrls: ['./publicite.component.css']
})
export class PubliciteComponent implements OnInit {

  _posts: Post[] = []

  constructor(
    private backEndApiService: BackEndApiService
  ) { }

  ngOnInit() {
  }

  get4Posts () {
    this.backEndApiService.get4Posts().subscribe((response: any) => {
      response.map((post: any) => {
        post = new Post(post);
        this._posts.push(post)
      })
    })
  }

}
