import { Component, Input, OnInit } from '@angular/core';
import { Post } from 'src/app/core/models/post';

@Component({
  selector: 'app-news-item-screen-linear',
  templateUrl: './news-item-screen-linear.component.html',
  styleUrls: ['./news-item-screen-linear.component.css']
})
export class NewsItemScreenLinearComponent implements OnInit {

  @Input() post: Post;

  constructor() { }

  ngOnInit() {
  }

}
