import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
//import { MatFormField, MatLabel } from '@angular/material';
import {MatFormFieldModule} from '@angular/material/form-field';

import { MatLabel } from '@angular/material/form-field';
import { NgxNewstickerAlbeModule } from 'ngx-newsticker-albe';
import { NgbModalModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { NewsItemScreenComponent } from './components/news-item-screen/news-item-screen.component';
import { NewsItemNoScreenComponent } from './components/news-item-no-screen/news-item-no-screen.component';
import { NewsItemCoverScreenComponent } from './components/news-item-cover-screen/news-item-cover-screen.component';
import { NewsItemScreenLinearComponent } from './components/news-item-screen-linear/news-item-screen-linear.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CKEditorModule } from 'ngx-ckeditor';
import { AngMusicPlayerModule } from 'ang-music-player';
import { PlayerComponent } from './components/player/player.component';
import { RouterModule } from '@angular/router';
import { YouTubePlayerModule } from '@angular/youtube-player';
import {MatChipsModule} from '@angular/material/chips';
import { MatProgressSpinnerModule } from '@angular/material';
import {NgxPaginationModule} from 'ngx-pagination';
import {MatCardModule} from '@angular/material/card';


@NgModule({
  declarations: [
    NewsItemScreenComponent, 
    NewsItemNoScreenComponent, 
    NewsItemCoverScreenComponent, 
    NewsItemScreenLinearComponent, 
    PlayerComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    AngMusicPlayerModule,
    YouTubePlayerModule,
    RouterModule
  ],
  exports: [
    CommonModule,
    MatSidenavModule,
    MatIconModule,
    MatToolbarModule,
    MatListModule,
    MatMenuModule,
    MatChipsModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    NgxNewstickerAlbeModule,
    NgbModalModule,
    NewsItemScreenComponent,
    NewsItemNoScreenComponent,
    NewsItemCoverScreenComponent,
    NewsItemScreenLinearComponent,
    NgbPaginationModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    CKEditorModule,
    AngMusicPlayerModule,
    PlayerComponent,
    NgxPaginationModule,
    MatCardModule
  ]
})
export class SharedModule { }
