import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Audio } from '../core/models/audio';
import { Post } from '../core/models/post';
import { BackEndApiService } from '../core/services/back-end-api.service';
import { StoreService } from '../core/services/store.service';
import { Meta, Title } from '@angular/platform-browser';
import { FacebookService } from 'ngx-facebook';
@Component({
  selector: 'app-actualite-details',
  templateUrl: './actualite-details.component.html',
  styleUrls: ['./actualite-details.component.css']
})
export class ActualiteDetailsComponent implements OnInit {

  public post: Post;
  public postId = this.route.snapshot.paramMap.get('id');
  public hashtagsv2 : any[] ;
  public _posts: Post[] = []
  public _postsAlamia: Post[] = []
  baseUrl = environment.backendEndpoint;
public audios : any ; 
  constructor(
    private backEndApiService: BackEndApiService,
    private route: ActivatedRoute,
    private storeService: StoreService,
    private http : HttpClient,
    private meta: Meta, private title: Title,
    private fb: FacebookService
  ) { }
facebookUrl : any;
  ngOnInit() {

    
    this.http.get("http://167.86.107.74:1212/audio/"+this.postId).subscribe(data => this.audios = data  , ex => console.log(ex) ) ;

    localStorage.setItem('idfacebook', this.postId);
    



    window.scroll(0, 0)
    this.storeService.postId.next(this.postId)
    this.getPostById(this.postId)
    this.gethashtags(this.postId)
    
    this.storeService.postId.subscribe((response: any) => {
      this.postId = response      
      this.getPostById(this.postId)
    })
    this.get4Posts()
    this.get4PostsAlamia()
  }

  getPostById (postId) {
    this.backEndApiService.getPostById(postId).subscribe((response: any) => {
      this.post = new Post (response);
      console.log("yahyaaaa",this.post.picture)
      this.title.setTitle(response.title);
      this.meta.addTag({property: 'og:url', content: 'http://nefzawa.net/!/#/actualite-details/'+postId});
      this.meta.addTag({property: 'og:image', content: this.post.picture});
   
    })
  }

  get4Posts () {
    this.backEndApiService.get4Posts().subscribe((response: any) => {
      response.map((post: any) => {
        post = new Post(post);
        this._posts.push(post)
      })
    })
  }

  get4PostsAlamia () {
    this.backEndApiService.get4PostsAlamia().subscribe((response: any) => {
      response.map((post: any) => {
        post = new Post(post);
        this._postsAlamia.push(post)
      })
    })
  }


gethashtags(id:any){



  this.http.get<any>('http://167.86.107.74:1212/posts/'+id).subscribe(res => this.hashtagsv2= JSON.parse(res.hashtags)  , ex => console.log(ex)) ;

}

share() {



  this.fb.init({
    appId      : '666899911791398',
    cookie     : true,
    xfbml      : true,
    version    : 'v3.3'
  });

  this.fb.ui({
    method: 'share',
    display: 'popup',
    href: 'nefzawa.net/!/#/actualite-details/63b55a30c5c303464e76adfd',
    picture: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/1200px-Image_created_with_a_mobile_phone.png',
    description: 'Page Description',
   
  });

}


}
