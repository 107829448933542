import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Post } from '../core/models/post';
import { BackEndApiService } from '../core/services/back-end-api.service';
import { StoreService } from '../core/services/store.service';

@Component({
  selector: 'app-actualites',
  templateUrl: './actualites.component.html',
  styleUrls: ['./actualites.component.scss']
})
export class ActualitesComponent implements OnInit {
  p: number = 1;
  public page: string;
  public posts: Post[] = []
  public titlePage: string;
  public categoryId = this.route.snapshot.paramMap.get('id');
  public _posts: Post[] = []
  public _postsAlamia: Post[] = []

  constructor(
    private route: ActivatedRoute,
    public storeService: StoreService,
    private backEndApiService: BackEndApiService
  ) {
   }

  ngOnInit() {
    window.scroll(0, 0)
    this.get4Posts()
    if (this.categoryId) {
      this.getPostsByCategory(this.categoryId)
      this.titlePage = this.categoryId
    }
    this.storeService.titlePage.subscribe((response: string) => {
      this.titlePage = response
      this.posts = []
      this.getPostsByCategory(this.titlePage)
    })
    this.get4PostsAlamia()
  }

  getPostsByCategory (category) {
    this.backEndApiService.getAllPostsByCategory(category).subscribe((response: any) => {
      response.map(post => {
        this.posts.push(new Post(post))
      })
    })
  }

  get4Posts () {
    this.backEndApiService.get4Posts().subscribe((response: any) => {
      response.map((post: any) => {
        post = new Post(post);
        this._posts.push(post)
      })
    })
  }

  get4PostsAlamia () {
    this.backEndApiService.get4PostsAlamia().subscribe((response: any) => {
      response.map((post: any) => {
        post = new Post(post);
        this._postsAlamia.push(post)
      })
    })
  }
}
