import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Post } from '../core/models/post';
import { BackEndApiService } from '../core/services/back-end-api.service';

@Component({
  selector: 'app-emission',
  templateUrl: './emission.component.html',
  styleUrls: ['./emission.component.css']
})
export class EmissionComponent implements OnInit {

  public posts: Post[] = []
  _posts: Post [] = []
  public emission = this.route.snapshot.paramMap.get('id');

  constructor(
    private backEndApiService: BackEndApiService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.getPostsByCategory(this.emission)    
  }

  getPostsByCategory (category) {
    this.backEndApiService.getAllPostsByCategory(category).subscribe((response: any) => {
      response.map(post => {
        this.posts.push(new Post(post))
      })
    })
  }

  get4Posts () {
    this.backEndApiService.get4Posts().subscribe((response: any) => {
      response.map((post: any) => {
        post = new Post(post);
        this._posts.push(post)
      })
    })
  }
}
