import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ondes',
  templateUrl: './ondes.component.html',
  styleUrls: ['./ondes.component.css']
})
export class OndesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  counter(i: number) {
    return new Array(i);
}
}
