import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nous',
  templateUrl: './nous.component.html',
  styleUrls: ['./nous.component.css']
})
export class NousComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scroll(0, 0)
  }

  counter(i: number) {
    return new Array(i);
}

}
