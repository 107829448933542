import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { NewsLetter } from '../core/models/news-letter';
import { BackEndApiService } from '../core/services/back-end-api.service';

@Component({
  selector: 'app-newletter',
  templateUrl: './newletter.component.html',
  styleUrls: ['./newletter.component.scss']
})
export class NewletterComponent implements OnInit {

  programmeForm: FormGroup;
  registerForm: FormGroup;
  SnackMessage="Email ajouté avec succèes";
  submitted = false;
  public programmes: NewsLetter[] = []

 

  constructor(
    private formBuilder: FormBuilder,
    private backEndApiService: BackEndApiService,
    private snackBar:MatSnackBar
  ) { }

  ngOnInit(): void {
    this.getAllTvs();
    this.registerForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      
    })
  }

  getAllTvs() {
    this.backEndApiService.getAllNewsletter().subscribe((response: any) => {
      response.map((programme: any) => {
        programme = new NewsLetter(programme);
        this.programmes.push(programme)
      })
    })
  }

  onFileSelected(event) {
    if(event.target.files.length > 0) 
     {
       this.programmeForm.patchValue({
          fileName: event.target.files[0],
       })
     }
  }
  
  get f() { return this.registerForm.controls; }

 

  deleteProgramme (programmeId) {
    this.backEndApiService.deleteNewsletter(programmeId).subscribe((response: any) => {
      this.programmes = []
      this.getAllTvs()
    })
  }
  
  register(f:any){
    let data=f.value

    this.backEndApiService.createNewsletter(data).subscribe(data=>{
      this.programmes.unshift(new NewsLetter (data));
        
      this.submitted = false;
       })
f.resetForm();
this.snackBar.open(this.SnackMessage,'',{
  duration:4000,
  verticalPosition:'bottom',
  panelClass:'snackBar'
  
});
  }
 
}