import { environment } from "src/environments/environment";

export class Equipe {
    id: string;
    title: string;
    description: string;
    picture: string;
    created_at: string;
    
    constructor(data) {
        this.id = data._id;
        this.title = data.title;
        this.description = data.description;
        this.picture = environment.backendEndpoint + data.file
        this.created_at = data.created_at
    }
}
