import { Component, OnInit } from '@angular/core';
import { Equipe } from '../core/models/equipe';
import { BackEndApiService } from '../core/services/back-end-api.service';

@Component({
  selector: 'app-equipe',
  templateUrl: './equipe.component.html',
  styleUrls: ['./equipe.component.css']
})
export class EquipeComponent implements OnInit {

  public equipes: Equipe[] = [] 

  constructor(
    private backEndApiService: BackEndApiService
  ) { }

  ngOnInit() {
    this.getAllEquipes()
  }

  getAllEquipes () {
    this.backEndApiService.getAllEquipes().subscribe((response: any) => {
      response.map((equipe: any) => {
        equipe = new Equipe(equipe);
        this.equipes.push(equipe)
      })
    })
  }
}
