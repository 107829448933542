import { Component, OnInit } from '@angular/core';
import { Post } from '../core/models/post';
import { BackEndApiService } from '../core/services/back-end-api.service';

@Component({
  selector: 'app-audio',
  templateUrl: './audio.component.html',
  styleUrls: ['./audio.component.scss']
})
export class AudioComponent implements OnInit {

  _posts: Post[] = []
  _postsAlamia: Post[] = []

  constructor(
    private backEndApiService: BackEndApiService
  ) { }

  ngOnInit() {
  }

  get4Posts () {
    this.backEndApiService.get4Posts().subscribe((response: any) => {
      response.map((post: any) => {
        post = new Post(post);
        this._posts.push(post)
      })
    })
  }

  get4PostsAlamia () {
    this.backEndApiService.get4PostsAlamia().subscribe((response: any) => {
      response.map((post: any) => {
        post = new Post(post);
        this._postsAlamia.push(post)
      })
    })
  }



  audioList = [
    {
      url: "http://stream-58.zeno.fm/x26qrvf6by8uv?zs=SSJ17qnWTleWGt0uaPok2Q",
      title: "Live audio Nefzawa FM",
      cover: "./assets/LIVE.png"
    },

  ];

}
