import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'nefzawa-front';
  dark: boolean = false

  constructor(
    public router: Router
    
  ){

    const timeZoneOffset = new Date().getHours();
    console.log(timeZoneOffset )
    if (timeZoneOffset > 19 ){
      document.body.classList.add('dark-theme')
  
    } else {
     
     
      document.body.classList.remove('dark-theme');
      
    }
  }
  ngOnInit() {

    const timeZoneOffset = new Date().getHours();
    console.log(timeZoneOffset )
    if (timeZoneOffset > 19 ){
      document.body.classList.add('dark-theme')
  
    } else {
     
     
      document.body.classList.remove('dark-theme');
      
    }
  }

  darkMode() {

   
    this.dark = !this.dark
    if (this.dark ) {
      document.body.classList.toggle('dark-theme');
    } else {
      document.body.classList.remove('dark-theme');
    }
  }


  
}
