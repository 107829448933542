import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Post } from '../core/models/post';
import { Programme } from '../core/models/programme';
import { BackEndApiService } from '../core/services/back-end-api.service';

@Component({
  selector: 'app-programme',
  templateUrl: './programme.component.html',
  styleUrls: ['./programme.component.css']
})
export class ProgrammeComponent implements OnInit {

  public programmes: any;
  _posts: Post[] = []

  baseUrl = environment.backendEndpoint;
  constructor(
    private backEndApiService: BackEndApiService, private http : HttpClient
  ) { }

  ngOnInit() {
    this.getAllProgrammes();
    this.http.get(this.baseUrl+"programmesGlobal/all").subscribe(res => this.programmes = res , ex => console.log(ex) ) ;
  }

  getAllProgrammes () {
    this.backEndApiService.getAllProgrammes().subscribe((response: any) => {
      response.map((programme: any) => {
        programme = new Programme(programme);
        this.programmes.push(programme)
      })
    })
  }

  get4Posts () {
    this.backEndApiService.get4Posts().subscribe((response: any) => {
      response.map((post: any) => {
        post = new Post(post);
        this._posts.push(post)
      })
    })
  }
}
