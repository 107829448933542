import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ActualiteDetailsComponent } from './actualite-details/actualite-details.component';
import { ActualitesComponent } from './actualites/actualites.component';
import { AinNefzawaComponent } from './ain-nefzawa/ain-nefzawa.component';
import { AudioComponent } from './audio/audio.component';
import { BraodcastComponent } from './braodcast/braodcast.component';
import { ContactComponent } from './contact/contact.component';
import { AuthGuardService } from './core/services/auth-guard.service';
import { EmissionComponent } from './emission/emission.component';
import { EquipeComponent } from './equipe/equipe.component';
import { HomePageComponent } from './home-page/home-page.component';
import { LoginComponent } from './login/login.component';
import { Mouwafa9Component } from './mouwafa9/mouwafa9.component';
import { NousComponent } from './nous/nous.component';
import { ProgrammeComponent } from './programme/programme.component';
import { PubliciteComponent } from './publicite/publicite.component';
import { VideoNTVComponent } from './video-ntv/video-ntv.component';
import { Mitha9Component } from './mitha9/mitha9.component';
import { LiveComponent } from './live/live.component';
import { OndesComponent } from './ondes/ondes.component';
import { HistoireComponent } from './histoire/histoire.component';
import { MazedComponent } from './mazed/mazed.component';
import { GieComponent } from './gie/gie.component';
import { MasComponent } from './mas/mas.component';
import { TourismeupComponent } from './tourismeup/tourismeup.component';
import { MeteoComponent } from './meteo/meteo.component';
import { ProductionComponent } from './production/production.component';
import { NewletterComponent } from './newletter/newletter.component';
import { BodcastHomeComponent } from './bodcast-home/bodcast-home.component';
import { Bodcast1Component } from './bodcast1/bodcast1.component';
import { Broadcast2Component } from './broadcast2/broadcast2.component';
import { AlonefzawaComponent } from './alonefzawa/alonefzawa.component';

const routes: Routes = [
  { 
    path: '',
    component: HomePageComponent
  },
  {
    path: 'contact',
    component: ContactComponent
  },
  {
    path: 'actualites/:id/:page',
    component: ActualitesComponent
  },
  {
    path: 'actualite-details/:id',
    component: ActualiteDetailsComponent
  },
  {
    path: 'audio',
    component: AudioComponent
  },
  {
    path: 'equipes',
    component: EquipeComponent
  },
  {
    path: 'programmes',
    component: ProgrammeComponent
  },
  {
    path: 'emissions/:id/baramej',
    component: EmissionComponent
  },
  {
    path: 'publicite',
    component: PubliciteComponent
  },
  {
    path: 'عين-نفزاوة',
    component: AinNefzawaComponent
  },
  {
    path: 'nefzawa-broadcast',
    component: BraodcastComponent
  },
  {
    path: 'nahnou',
    component: NousComponent
  },

  {
    path: 'mitha9',
    component:  Mitha9Component
  },


  {
    path: 'nefzawa/wp-login',
    component: LoginComponent
  },  
  
  {
    path: 'videoNFT',
    component: VideoNTVComponent
  },
  {
    path: 'mouwafa9',
    component: Mouwafa9Component
  },

  {
    path: 'live',
    component: LiveComponent
  },
  
  {
    path: 'ondes',
    component: OndesComponent
  },
  {
    path: 'histoire',
    component: HistoireComponent
  },
  {
    path: 'mazed',
    component: MazedComponent
  },
  {
    path: 'gie',
    component: GieComponent
  },
  {
    path: 'mas',
    component: MasComponent
  },
  {
    path: 'tourisme',
    component: TourismeupComponent
  },

  {
    path: 'meteo',
    component: MeteoComponent
  },
  
  {
    path: 'production',
    component: ProductionComponent
  },

  {
    path: 'newsletter',
    component: NewletterComponent
  },
  {
    path: 'bodcastHome',
    component: BodcastHomeComponent
  },
  {
    path: 'bodcastNi9ach',
    component: Bodcast1Component
  },

  {
    path: 'bodcast2',
    component: Broadcast2Component
  },
  {
    path: 'alo',
    component: AlonefzawaComponent
  },
  {
    path: 'nefzawa/wp-admin',
    loadChildren: () => import('./nefzawa-dashboard/nefzawa-dashboard.module').then(m => m.NefzawaDashboardModule),
    canActivate: [AuthGuardService]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
