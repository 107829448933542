import { environment } from "src/environments/environment";

export class Post {
    id: string;
    title: string;
    description: string;
    picture: string;
    created_at: string;
    facebook : string ; 
    responsable : string ;
    hashtags : String ; 
    legende : String ;
    status : Boolean ; 

    
    constructor(data) {
        this.id = data._id;
        this.title = data.title;
        this.description = data.description;
        this.picture = environment.backendEndpoint + data.file;
        this.created_at = data.created_at;
        this.facebook =   data.facebook ;
        this.responsable = data.responsable ;
        this.hashtags = data.hashtags ;
        this.legende = data.legende ;
        this.status = data.status;
    }
}
